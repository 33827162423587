import create from 'zustand'

const useZtore = create(set => ({

  student: [],
  setStudent: s => {
    set(state => ({
      student: s
    }))
  },
  
  menuValue: 'home',
  setMenuValue: m => {
    set(state => ({
      menuValue: m
    }))
  },
  
  lightDarkMode: 'dark',
  setLightDarkMode: dm => {
    set(state => ({
      lightDarkMode: dm
    }))
  },
  
  apiError: {},
  setApiError: e => {
    set(state => ({
      apiError: e
    }))
  },

  userId : {},
  setUserId: u => {
    set(state => ({
      userId: u
    }))
  },

  // alert : {title:'Tónfræði fellur niður í dag', message: 'Bangsapabbi er veikur'},
  alert : [{}],
  setAlert: a => {
    set(state => ({
      alert: a
    }))
  },

  overlayDrawer: {},
  setOverlayDrawer: a => {
    set(state => ({
      overlayDrawer: a
    }))
  },
  
  settingsOverlay: {open: false},
  setSettingsOverlay: a => {
    set(state => ({
      settingsOverlay: a
    }))
  },

}))


export default useZtore

