import {
  useQuery,
} from '@tanstack/react-query';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import StudentBar from '../../StudentBar';
import AttItemCard from './AttItemCard';

export default function Student() {
  const { data, isLoading } = useQuery({ queryKey: ['/student'] });
  // console.log('Student',data)

  if (!isLoading && data.data.student) {
    return (
         data?.data.student.map((stud, i) => (
         <Grid2 key={i} container spacing={0.5}>
           <Grid2 container xs={12}>
             <Grid2 xs={12}>
               <StudentBar name={stud.name} />
             </Grid2>
           </Grid2>
           
           {stud.subject?.map((sub, i) => {
            //  const weekday = sub.dayOfWeek ? sub.dayOfWeek : ''
            //  const timeofday = sub.timeOfDay ? ' kl.'+sub.timeOfDay : ''
            //  const head = `${weekday}${timeofday}`
            //  const head = stud.name
             return (
             <Grid2 key={i} xs={12} md={6} lg={4}>
              <AttItemCard
              //  title={sub.subjName}
               sub={sub}
              />
           </Grid2>
               )})}

         </Grid2>
       ))
    );
  }
}
