import { Chip, Avatar, Stack } from "@mui/material"
import { singlePlur } from "../../common/stringFunctions"

export default function AttLine(props) {
  const {abs, att, lat, nor, rec, sic} = props.props

  // console.log(props)
  return (
    <Stack direction='row' spacing={2} sx={{mt: 2}}>
      {att > 0 && <Chip color="primary" avatar={<Avatar>{att}</Avatar>} label= "M" />}
      {lat > 0 && <Chip color="warning" avatar={<Avatar>{lat}</Avatar>} label= "S" />}
      {abs > 0 && <Chip color="error" avatar={<Avatar>{abs}</Avatar>} label= "F" />}
      {rec > 0 && <Chip color="primary" avatar={<Avatar>{rec}</Avatar>} label= "L" />}
      {sic > 0 && <Chip color="warning" avatar={<Avatar>{sic}</Avatar>} label= "V" />}
    </Stack>
  )
}
