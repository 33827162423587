import useZtore from './common/ztore'

import Paper from '@mui/material/Paper'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import HomeIcon from '@mui/icons-material/Home'
import SickIcon from '@mui/icons-material/Sick'
import MessageIcon from '@mui/icons-material/Message'
import EventNoteIcon from '@mui/icons-material/EventNote'
import PaidIcon from '@mui/icons-material/Paid'
import MenuIcon from '@mui/icons-material/Menu'

export default function FixedNavigation() {
  const setLightDarkMode = useZtore((state) => state.setLightDarkMode)
  const menuValue = useZtore((state) => state.menuValue)
  const setMenuValue = useZtore((state) => state.setMenuValue)

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={5}
    >
      <BottomNavigation
        showLabels
        value={menuValue}
        onChange={(event, newValue) => {
          setMenuValue(newValue)
          // console.log(newValue)
        }}
      >
        <BottomNavigationAction 
          label="Heim" 
          value="home" 
          icon={<HomeIcon />} 
        />
        <BottomNavigationAction
          label="Skilaboð"
          value="message"
          icon={<MessageIcon />}
        />
        <BottomNavigationAction
          label="Mæting"
          value="attendance"
          icon={<EventNoteIcon />}
        />
        <BottomNavigationAction
          label="Meira"
          value="more"
          icon={<MenuIcon />}
        />
      </BottomNavigation>
    </Paper>
  )
}
