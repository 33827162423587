import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

function AlertBox(props) {
  // const { severity = 'warning', title, message } = props.alert
  const severity = 'warning'
  // console.log('alertbox', props.alert)

  return (
    props.alert.map(al => (
      < Alert variant="filled" severity={severity} >
        <AlertTitle>{al.title}</AlertTitle>
        {al.message}
      </Alert >

    ))
  )
}

export default AlertBox
