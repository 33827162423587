import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'

import Toolbar from '@mui/material/Toolbar'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
// import { GetSettings } from './common/HttpService'
import Typography from '@mui/material/Typography'

const SchoolBar = () => {
  axios.defaults.withCredentials = true

  const { data } = useQuery({ queryKey: ['/user'] })

  const sendSettings = async (data) => {
    // const resp = await axios.post('https://www.viska.is/api/v1/controller/student/settings.php', data)
    const resp = await axios.post('https://www.viska.is/api/v1/user', data)
    
    // TODO leysa þetta betur
    window.location.reload()
    return resp.data
  }

  const queryClient = useQueryClient()

  const mutation = useMutation(sendSettings, {
    
    onMutate: async (school) => {
      // console.log('school', school)
      await queryClient.cancelQueries(['/user'])
      // const prevData = queryClient.getQueryData(['/user'])
      // console.log('prevData ',prevData)
      // queryClient.setQueryData(['/user', data.data.settings.school], school)
      // console.log('getQueryData', queryClient.getQueryData(['/user']))
      // queryClient.setQueryData(['/user'], (old) => old ? 
      // [old.data.settings.school, school.school]
      // : prevData.data.settings.school)

      // queryClient.setQueryData(['/user'], (old) => {console.log('old',old.data)})
    },
    
    onSuccess: (data) => {
      // API POST returns the updated data, no need to refetch or invalidate 
      queryClient.setQueryData(['/user'], data)

      // queryClient.refetchQueries(['/home'])
      // queryClient.invalidateQueries(["/student"])
      // .then(queryClient.refetchQueries(['/user']))
    },
  })

  const handleChange = (e) => {
    // console.log('select value: ', e.target.value)
    // console.log('data on handleChange: ', data)
    mutation.mutate({ school: e.target.value })
  }

  return (
    <Toolbar
      variant="dense"
      className="toolbar"
      sx={{ bgcolor: 'rgba(127,127,127,0.2)' }}
    >
      <div></div>
      <FormControl sx={{ mt: 2, minWidth: 300 }} size="small">
        {data?.data.select.length > 1
        ? (
          <Select
            // variant='contained'
            labelId="school"
            id="school"
            defaultValue={0}
            value={data?.data.settings.school || 0}
            // value={selVal}
            onChange={handleChange}
          >
            {data?.data.select.map((sel, i) => {
              return (
                <MenuItem key={i} value={sel.schoolNr}>
                  {sel.schoolName}
                </MenuItem>
              )
            })}
          </Select>
        ) : (
          <Typography variant='h6' sx={{ textAlign: 'center', pb: 1 }}>
            {data?.data.select[0]?.schoolName}
          </Typography>
        )}
      </FormControl>
      <div></div>
    </Toolbar>
  )
}

export default SchoolBar
