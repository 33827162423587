import useZtore from './common/ztore';
import Box from '@mui/material/Box';

import Student from './pages/Attendance/Student';
import { Home } from './pages/Home';
import { Message } from './pages/Message';
import { Payment } from './Payment';
import { Typography } from '@mui/material';

export default function Content() {

  const menuValue = useZtore((state) => state.menuValue);

  return (
    <Box sx={{ m: 1, mb: 10 }}>
      {menuValue === 'home' ? 
        <Home />
        :
        menuValue === 'attendance' ?
        <Student /> :
        menuValue === 'message' ?
        <Message /> :
        menuValue === 'payment' ?
        <Payment />
      : ''}
    </Box>
  );
}
