import { Button } from '@mui/material'
import useZtore from './common/ztore'

export const UserSettings = () => {
   const setLightDarkMode = useZtore((state) => state.setLightDarkMode)
   const lightDarkMode = useZtore((state) => state.lightDarkMode)
   const setOverlayDrawer = useZtore((state) => state.setOverlayDrawer)
   const setTheme = () => {
      setLightDarkMode(lightDarkMode === 'light' ? 'dark' : 'light')
      setOverlayDrawer({})
   }

   
  return (
   <Button
   variant={'contained'}
    onClick={setTheme}>
      {lightDarkMode === 'light' ? "Dökkt þema" : "Ljóst þema"}
   </Button>
  )
}
