import { useState, useEffect } from 'react';
import axios from 'axios'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient
} from '@tanstack/react-query';
import { Logout } from './common/HttpService';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import useZtore from './common/ztore';

import AvatarEdit from './AvatarEdit';
import { UserSettings } from './Settings';
import { ToggleOff, ToggleOn } from '@mui/icons-material';

export default function AccountMenu() {
  const queryClient = useQueryClient();
  const { data } = useQuery({ queryKey: ['/user'] });

  axios.defaults.withCredentials = true


  const sendSettings = async (data) => {
    // const resp = await axios.post('https://www.viska.is/api/v1/controller/student/settings.php', data)
    const resp = await axios.post('https://www.viska.is/api/v1/user', data)
    // console.log('resp.data', resp.data)
    return resp.data
  }


  const mutation = useMutation(sendSettings, {
    onMutate: async (displayMode) => {
      await queryClient.cancelQueries(['/user'])
      const prevData = queryClient.getQueryData(['/user'])
    },
    
    onSuccess: (data) => {
      // API POST returns the updated data, no need to refetch or invalidate 
      queryClient.setQueryData(['/user'], data)

    },
  })











  const setSettingsOverlay = useZtore((state) => state.setSettingsOverlay);
  const settingsOverlay = useZtore((state) => state.settingsOverlay);
  const setOverlayDrawer = useZtore((state) => state.setOverlayDrawer);
  const setLightDarkMode = useZtore((state) => state.setLightDarkMode);
  const lightDarkMode = useZtore((state) => state.lightDarkMode);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // useEffect (() => {
  //   console.log(settingsOverlay)
  // }, [settingsOverlay] )

  const avatarDir = 'https://viska.is/api/avatar/';
  const handleClick = (event) => {
    // setLightDarkMode(lightDarkMode === 'dark' ? 'light' : 'dark')
    setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemClick = (e, val) => {
    // console.log('itemClick', val);
    if (val === 'settings') {
      setOverlayDrawer({
        head: 'Stillingar',
        time: null,
        children: <UserSettings />
      });
    } else if (val === 'profile') {
      setSettingsOverlay({ title: 'Prófílmynd', children: <AvatarEdit /> });
    }
  };
  const setTheme = () => {
    const mode = lightDarkMode === 'light' ? 'dark' : 'light';
    setLightDarkMode(mode)

    mutation.mutate({ displayMode: mode })
    // setOverlayDrawer({})
  };

  const handleLogout = () => {
    Logout().then((res) => queryClient.invalidateQueries(['/user']));
  };

  return (
    <>
      {data?.data.id ? (
        <div>
          <Box
            sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
          >
            {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography> */}
            <Typography sx={{ fontSize: '1.5rem' }}>
              {data?.data.settings.userInits}
            </Typography>
            <Tooltip title='Account settings'>
              <IconButton
                onClick={handleClick}
                size='medium'
                sx={{ ml: 1 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
              >
                {data?.data.settings.avatar ? (
                  <Avatar
                    src={avatarDir + data?.data.settings.avatar}
                    sx={{ width: 56, height: 56 }}
                  />
                ) : (
                  <AccountCircle sx={{ width: 48, height: 48 }} />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem>
            {data?.data.settings.userName}
            </MenuItem>
            <MenuItem onClick={(event) => handleItemClick(event, 'profile')}>
              <Avatar /> Prófílmynd
            </MenuItem>

            <Divider />
            <MenuItem onClick={setTheme}>
              <ListItemIcon>
              {lightDarkMode === 'light' ? <ToggleOff /> : <ToggleOn />}
              </ListItemIcon>
                  {lightDarkMode === 'light' ? 'Dökkt þema' : 'Ljóst þema'}
            </MenuItem>

            <MenuItem onClick={(event) => handleItemClick(event, 'settings')}>
              <ListItemIcon>
                <Settings fontSize='small' />
              </ListItemIcon>
              Stillingar
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon fontSize='small' />
              </ListItemIcon>
              Skrá út
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
