import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'

const boxDefault = {
  display: 'flex',
  height: '40vh',
  paddingTop: '10vh',
  justifyContent: 'center',
}
const Login = () => {
  return (
    <>
      <Box sx={boxDefault}>
        <Button
          variant="contained"
          sx={{ height: 100, minWidth: 300 }}
          href='https://innskraning.island.is/?id=viska.is&authid=e32f2a03-e1b6-4537-9a57-c255321d11f3'
        >
          <Typography fontSize={32} letterSpacing="2px">
            Innskráning
          </Typography>
        </Button>
      </Box>
    </>
  )
}

export default Login
