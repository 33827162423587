// import { useQuery, useMutation, useQueryClient, QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
// import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import logo from './img/logo.png'
// import Clock from './Clock'
import AccountMenu from './AccountMenu'

export default function Header(props) {
  const { isLoading, error, data, isFetching } = useQuery({queryKey: ['/user']})

  if (isLoading) return 'Loading...'

  if (error) return 'An error has occurred: ' + error.message

  return (
    <>
      <AppBar position="fixed">
        <Toolbar className="toolbar">
          <Box component="img" alt="Logo" src={logo} sx={{ height: 40 }} />
          <Typography variant="h5">
            {/* <Clock /> */}
            Viska
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

// app authId e32f2a03-e1b6-4537-9a57-c255321d11f3
