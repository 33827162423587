import { useState, useEffect } from 'react'
import axios from 'axios'
import './App.css'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from '@tanstack/react-query'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Header from './Header'
import StudentBar from './StudentBar'
import { Box } from '@mui/system'
import Fade from '@mui/material/Fade'
import Content from './Content'
import AlertBox from './AlertBox'

import useZtore from './common/ztore'
import bglight from './img/bgnd-light.jpg'
import bgdark from './img/bgnd-dark.jpg'
import { GetSettings } from './common/HttpService'
import Login from './Login'
import SubjectOverlay from './SubjectOverlay'
import SettingsOverlay from './SettingsOverlay'
import SchoolBar from './SchoolBar'
import FixedNavigation from './FixedNavigation'
import OverlayDrawer from './OverlayDrawer'

const AppBody = (props) => {
  // const { mode, setMode } = props
  const { mode } = props
  const alert = useZtore((state) => state.alert)
  const setAlert = useZtore((state) => state.setAlert)
  const user = useZtore((state) => state.user)
  const setUser = useZtore((state) => state.setUser)
  const setLightDarkMode = useZtore((state) => state.setLightDarkMode)

  const [fade, setFade] = useState(false)

  const { data } = useQuery({ queryKey: ['/user'] })
  const id = data?.data.id
  const ldmode = data?.data.settings.displayMode

  // const { data: alertData, isSuccess } = useQuery(
  //   ['alert'],
  //   async () => {
  //     const res = await axios.get('https://viska.is/api/v1/alert')
  //     setAlert(res.data.data)
  //     console.log(res.data.data)
  //   },
  //   {
  //     refetchInterval: 2000,
  //   }
  // )


  useEffect(() => {
    setLightDarkMode(ldmode)
  }, [ldmode])

  useEffect(() => {
    setFade(true)
  }, [])

  return (
    <div className="appbody">
      <Fade in={fade} timeout={700}>
        <Box
          style={{
            // backgroundImage:
            //   mode === 'dark' ? `url(${bgdark})` : `url(${bglight})`,
            backgroundSize: 'cover',
            height: '100vh',
          }}
        >
          <Grid2 container direction="column">
            <Grid2>
              <Header />
              <SchoolBar />
              <OverlayDrawer />
              {/* <StudentBar /> */}
            </Grid2>

            <Grid2 container>
              <Grid2 xs={false} sm={1} />

              <Grid2 xs={12} sm={10}>
                {/* {alert[0].title && <AlertBox alert={alert} />} */}
                {/* <SubjectOverlay /> */}
                <SettingsOverlay />

                {id ? <Content /> : <Login />}
              </Grid2>

              <Grid2 xs={false} sm={1} />
            </Grid2>
          </Grid2>
        </Box>
      </Fade>
      {id && <FixedNavigation />}
    </div>
  )
}

export default AppBody
