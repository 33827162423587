import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button'
// import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import useZtore from './common/ztore'
import SmartDate from './SmartDate.jsx'
import { Alert, AlertTitle, Paper, Stack } from '@mui/material'

export default function ItemCard(props) {
  const setOverlayDrawer = useZtore(state => state.setOverlayDrawer)

  const { head = '', subjectName, time, place, eventName, name, dayOfWeek, teacherShort, tSickDay, tSickText } = props
  // console.log('ItemCard props:', props)

  // console.log(props)

  const cardClick = (props) => {
    // console.log('cardClick props', props)
    // const title = `${smartDate(props.time, true)} - ${props.title}`
    // setOverlayDrawer({title: title, description: props.description, place: props.place})
    setOverlayDrawer(props)
  }


  return (
    <>
      {tSickDay && <Typography onClick={() => cardClick(props)}
        sx={{ mt: 2, mb: -1, pt: 1, pb: 1, pl: 2, pr: 2, fontSize: 22, backgroundColor: '#ed6c02', width: '99%' }}
      >
        Forföll kennara
      </Typography>}

      <Card onClick={() => cardClick(props)} sx={{ width: '99%', height: '7em', mt: 0, mb: 2 }} >
        <CardActionArea>
          <CardContent sx={{ ...(tSickDay > 0 && { backgroundColor: '#ed6c02' }) }} >

            {time && <SmartDate stamp={time} />}
            {!time && <Typography variant="h5">{dayOfWeek}</Typography>}
            <Typography variant='h6'>
              {name}
            </Typography>

            <Stack direction={'row'} spacing={2}>

              {subjectName && <Typography >
                {subjectName}
              </Typography>}
              {teacherShort && <Typography color="text.secondary">
                ( {teacherShort} )
              </Typography>}
            </Stack>

          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}
