import { useQuery, useMutation, useQueryClient, QueryClient } from '@tanstack/react-query'
import { Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
// import { GetSettings } from './common/HttpService'

const StudentBar = (props) => {
  const {name} = props
  // const {data} = useQuery({queryKey: ['/user']})

  return (
    <Toolbar variant='dense' className='toolbar' sx={{bgcolor: 'rgba(127,127,127,0.3)', mt: 2}}>
      <div></div>
      <Typography color="text.secondary" variant='h6' sx={{fontWeight: 'bold'}}>
      {name}
      </Typography>
      <div></div>
    </Toolbar>
  )
}

export default StudentBar

