import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button'
// import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import useZtore from '../../common/ztore'
import SmartDate from '../../SmartDate.jsx'
import AttLine from './AttLine'
import { Stack } from '@mui/material'
import AttendanceSum from './AttendanceSum'

export default function AttItemCard(props) {
  const setOverlayDrawer = useZtore(state => state.setOverlayDrawer)

  const { head = '', time, place, subjName, teacherShort, name, dayOfWeek, attend } = props.sub
  // console.log('AttItemCard props:', props)

  // convert to string for mui Chip
  const attendance = {
    att: "" + attend.att,
    abs: "" + attend.abs,
    lat: "" + attend.lat,
    nor: "" + attend.nor,
    rec: "" + attend.rec,
    sic: "" + attend.sic,
    proc: attend.proc,
  }

  const cardClick = (props) => {
    // console.log('cardClick props', props)
    // const title = `${smartDate(props.time, true)} - ${props.title}`
    // setOverlayDrawer({title: title, description: props.description, place: props.place})
    setOverlayDrawer(props)

  }

  return (
    // sx={{bgcolor: 'rgba(127,127,127,0.3)', mt: 2}}
    // <Card onClick={() => cardClick(props)} sx={{height: '6em', borderTop: '1px solid #55908F', borderLeft: '1px solid #55908F'}}>
    <Card onClick={() => cardClick(props)} sx={{ height: '9em', mt: 1 }}>
      <CardActionArea>
        <CardContent>
          <Stack direction={'row'} spacing={2}>

            {subjName && <Typography >
              {subjName}
            </Typography>}
            {teacherShort && <Typography color="text.secondary">
              ( {teacherShort} )
            </Typography>}
          </Stack>

          <AttLine
            props={attendance}
          />

          <AttendanceSum
            props={attendance}
          />

        </CardContent>
      </CardActionArea>
    </Card>
  )
}
