import Typography from '@mui/material/Typography'

const SmartDate = (props) => {
  const {stamp} = props
  
  const dateObj = new Date(stamp*1000);
  const wd = ['SUN','MÁN','ÞRI','MIÐ','FIM','FÖS','LAU','SUN'];
  const mon = ['jan','feb','mar','apr','maí','jún','júl','ágú','sept','okt','nóv','des'];
  const day = dateObj.getDay();
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const theTime = `${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`
  const theDay = `${date}. ${mon[month]}.`

  return (
    <>
<Typography  variant="h5" display="inline" sx={{mr: 2}}>
  {theTime}
</Typography>
<Typography variant="h5" display="inline" sx={{mr: 2}}>
{wd[day]}
</Typography>
<Typography variant="h5" display="inline" sx={{mr: 2}}>
{theDay}
</Typography>
    </>
  )
}

export default SmartDate
