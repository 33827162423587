import useZtore from './common/ztore'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const SettingsOverlay = () => {
  const settingsOverlay = useZtore((state) => state.settingsOverlay)
  const setSettingsOverlay = useZtore((state) => state.setSettingsOverlay)

  const handleClose = () => {
    setSettingsOverlay({})
  }

  return (
    <>
      <Dialog
        PaperProps={{ sx: { position: "fixed", top: 40 } }}
        fullWidth={true}
        maxWidth={'xl'}
        open={Boolean(settingsOverlay.title)}
        onClose={handleClose}
        sx={{ maxHeight: '95vh' }}
      >
        <DialogTitle>
            {settingsOverlay.title}
        </DialogTitle>
        <DialogContent>
          {settingsOverlay.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SettingsOverlay
