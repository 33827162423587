import { useState } from 'react'
import useZtore from './common/ztore'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { styled, useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import SmartDate from './SmartDate'

export default function OverlayDrawer() {
  const overlayDrawer = useZtore((state) => state.overlayDrawer)
  const setOverlayDrawer = useZtore((state) => state.setOverlayDrawer)
  const open = Boolean(overlayDrawer.subjectName || overlayDrawer.head)

  const handleDrawerClose = () => {
    setOverlayDrawer({})
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }))

  return (
    <div>
      <Drawer anchor={'left'} open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
          {overlayDrawer.time ? <SmartDate stamp={overlayDrawer.time} /> : <Typography variant='h5'>{overlayDrawer.head}</Typography>}
        </DrawerHeader>
        <Divider />

        <Box sx={{ width: '100vw', p: '1em' }} role="presentation">
          <Typography variant="h5" sx={{ mb: 2 }}>
            {overlayDrawer.name}
          </Typography>
          <Typography variant="h6">
            {overlayDrawer.subjectName}
          </Typography>
          {overlayDrawer.gradeName && (
            <Typography sx={{ mb: 2 }}>
              {overlayDrawer.gradeName}
            </Typography>
          )}
          {overlayDrawer.place && (
            <Typography variant="h6">
              Kennslustaður: {overlayDrawer.place}
            </Typography>
          )}
          {overlayDrawer.teacherName && (
            <Typography variant="h6">
              Kennari: {overlayDrawer.teacherName}
            </Typography>
          )}
          {/* <Typography variant="body">{overlayDrawer.description}</Typography> */}
          {overlayDrawer.tSickDay &&
            <>
              <Typography sx={{ backgroundColor: '#ed6c02', p: 2, m: -1, mt: 2 }} variant='h4'>Forföll kennara</Typography>
              <Typography sx={{ backgroundColor: '#ed6c02', p: 2, m: -1, mt: 2 }} variant="h6">
                {(overlayDrawer.tSickText ? overlayDrawer.tSickText : overlayDrawer.teacherName)}
              </Typography>
            </>
          }
          {overlayDrawer.children}
        </Box>
      </Drawer>
    </div>
  )
}
