import { useQuery, useMutation, useQueryClient, QueryClient } from '@tanstack/react-query'
import axios from 'axios'
import useZtore from './ztore'
axios.defaults.withCredentials = true


export async function Logout() {
  const response = await axios.get('https://www.viska.is/api/v1/logout')
  window.location.reload()
  return response.data
}