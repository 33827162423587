import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import StudentBar from '../../StudentBar'
import ItemCard from '../../ItemCard'
import { Typography } from '@mui/material'
import Login from '../../Login'
import { Logout } from '../../common/HttpService'

const HomePage = () => {
  // const { data, isLoading } = useQuery({ queryKey: ['/home'] },
  // {
  //   refetchInterval: 2000,
  // })

  const { data, isLoading, isSuccess } = useQuery(
    ['/home'],
    async () => {
      const res = await axios.get('https://viska.is/api/v1/home')
      if (res.data.messages[0] === 'Forbidden') {
        // window.location.reload()
      }
      return res.data
    },
    {
      refetchInterval: 60000,
      // refetchInterval: 20000,
    }
  )



  // if (isSuccess && data.data.length === 0) {
  //   Logout()
  // }

  if (!isLoading) {
    return (
      <>
        <Typography color="text.secondary" sx={{textAlign: 'center'}}>Framundan:</Typography>
        {data.data.map((stud, i) => (
          <Grid2 key={i} container spacing={0.5}>
            <Grid2 container xs={12}>
              <Grid2 xs={12}>
                {/* <StudentBar name={stud.name} /> */}
                <ItemCard
                  subjectName={stud.subjectName}
                  time={stud.stampNext}
                  dayOfWeek={stud.dayOfWeek}
                  place={stud.roomName}
                  eventName={stud.eventName}
                  name={stud.name}
                  teacherShort={stud.teacherShort}
                  teacherName={stud.teacherName}
                  gradeName={stud.gradeName}
                  tSickDay={stud.tSickDay}
                  tSickText={stud.tSickText}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        ))}
      </>
    )
  }
}

export default HomePage
