import React, { useState } from 'react'
import Avatar from 'react-avatar-edit'
import axios from 'axios'
import useZtore from './common/ztore'
import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { Button } from '@mui/material'


const AvatarEdit = () => {
  const queryClient = useQueryClient()
  const [preview, setPreview] = useState()
  const [src, setSrc] = useState()
  const setSettingsOverlay = useZtore((state) => state.setSettingsOverlay)
  const avatarFileName = `avatar-${Math.floor(
    Math.random() * 1000000
  )}${Date.now()}.png`

  const label = 'Smelltu til að velja prófílmynd'

  const onClose = () => {
    setPreview(null)
  }

  const onCrop = (preview) => {
    // console.log('preview', preview)
    setPreview(preview)
  }

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 10168000) {
      alert('File is too big!')
      elem.target.value = ''
    }
  }

  const uploadToApi = async () => {
    const formData = new FormData()
    formData.append('filename', avatarFileName)
    formData.append('image', preview)
    const res = await axios
      .post('https://viska.is/api/v1/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // .then((res) => {
      //   queryClient.refetchQueries(['/user'])
      //   setSettingsOverlay({})
      // })
      // if (res.status === 200) {
      //   setSettingsOverlay({})
      //   queryClient.refetchQueries(['/user'])
      // }
  }

  const mutation = useMutation(uploadToApi, {
    onSuccess: () => {
      window.history.go(0)
      // queryClient.getQueryData({queryKey: ['/user']})
      // setSettingsOverlay({})
      // .getQueryData(['/user'])
    }
  })



  return (
    <div>
      <Avatar
        width={'100%'}
        height={250}
        label={label}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        // src={src}
      />
      {preview && (
        <>
          {/* <Button variant='contained' fullWidth sx={{mt: 2}} onClick={uploadToApi}> */}
          <Button variant='contained' fullWidth sx={{mt: 2}} onClick={mutation.mutate}>
            Vista
          </Button>
          {/* <img src={preview} height={350} width={350} alt="Preview" /> */}
        </>
      )}
    </div>
  )
}

export default AvatarEdit

// image="https://images.news18.com/ibnlive/uploads/2021/04/1618728765_einstein.jpg"
