// import { useEffect } from 'react'
import axios from 'axios'
import useZtore from './common/ztore'
// import { orange } from '@mui/material/colors'
// import Box from '@mui/material/Box'
// import CircularProgress from '@mui/material/CircularProgress'

import './App.css'
import AppBody from './AppBody';
// import { useUsers } from './common/HttpService'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { createTheme, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'


const defaultQueryFn = async ({ queryKey }) => {
  // const {data} = await axios.get('https://www.viska.is/api/v1/controller/student/settings.php')
  const { data } = await axios.get(`https://www.viska.is/api/v1${queryKey[0]}`)
  return data
}



function App() {
  const lightDarkMode = useZtore((state) => state.lightDarkMode)

  // const setAlert = useZtore((state) => state.setAlert);

  // const alertUrl = 'https://viska.is/api/v1/alert'

  // useEffect(()=>{
  //   const interval = setInterval(()=> {
  //     axios.get(alertUrl)
  //     .then(
  //       (res) => {
  //         console.log(res.data)
  //         setAlert(res.data.data)
  //       }
  //     )
  //   }, 3000)

  //   return ()=> clearInterval(interval)
  // })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        // staleTime: 1500,
        // notifyOnChangeProps: 'tracked',
      },
    },
  });

  const theme = createTheme({
    palette: {
      mode: (lightDarkMode === 'light') ? 'light' : 'dark',
      primary: {
        main: '#2A7473',
      },
      // secondary: {
      //   main: '#f50057',
      // },
    }
  })


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AppBody
          mode={lightDarkMode}
        />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
